 <template>
  <v-container fluid
               class="pa-0"
               style="width: 100% !important;">
    <v-alert v-if="shoppingCartError"
             type="error"
             width="80%"
             class="mt-6"
             style="margin-left: 10%;">
      <v-row no-gutters>
        <v-col cols="auto">{{shoppingCartError.status}}</v-col>
        <v-col cols="0"
               class="ml-3">{{errorText(shoppingCartError)}}</v-col>
      </v-row>
    </v-alert>

    <div v-else-if="shoppingCartLoading">
      <v-card class="pa-3">
        <v-skeleton-loader type="paragraph" />
        <v-skeleton-loader type="list-item" />
      </v-card>
      <v-skeleton-loader type="list-item" />
      <v-skeleton-loader v-for="index in 6"
                         :key="index"
                         type="list-item-avatar-two-line">
      </v-skeleton-loader>
    </div>

    <v-card-subtitle v-else-if="shoppingCart.length == 0">{{ $t("text.empty_shopping_cart") }}</v-card-subtitle>
    <v-container v-else
                 fluid
                 class="pa-0"
                 style="padding-bottom: 100px !important;">
      <v-row no-gutters
             style="position: sticky; 
                top: 0; 
                left: 0; 
                width: 100%; 
                z-index: 20;">
        <v-col cols="12">
          <v-card class="pa-3">
            <slot name="prepend-top"></slot>
            <v-textarea v-model="comment"
                        id="issuetextarea"
                        label="Note (optional)"
                        hint="Anything else the technician should know before delivering your new parts?"
                        outlined
                        rows="1"
                        auto-grow
                        hide-details="auto">
            </v-textarea>
            <v-card-text class="text-center font-weight-bold pb-0 pt-3"
                         style="font-size: 20px;">
              Cart total: {{ formatWithoutCurrency(getTotalSum) }}
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
      <template v-for="(cart, index) in shoppingCart">
        <v-row no-gutters
               :key="'cart-'+ index"
               class="my-2">
          <v-col>
            <h5 v-text="cart.Machine.DisplayName"
                class="mx-3"
                style="color: #4d4d4d; font-size: 15px;" />
            <v-list dense
                    class="OddMarkedList">
              <v-list-item v-for="(line) in cart.Items"
                           :key="line.Id"
                           class="px-0">
                <v-list-item-icon class="ma-0 mr-3">
                  <v-card width="55"
                          height="55"
                          flat
                          class="transparent rounded-0">
                    <v-img v-if="line.Article.ArticleImage != null"
                           :src="line.Article.ArticleImage"
                           max-height="55"
                           max-width="55"
                           contain
                           @error="imageLoadError(line, $event)"
                           @click="showItem(line)">
                      <template v-slot:placeholder>
                        <v-progress-circular indeterminate
                                             color="primary"
                                             size="30">
                        </v-progress-circular>
                      </template>
                    </v-img>
                    <v-img v-else
                           contain
                           max-height="55"
                           max-width="55"
                           :src="require('@/assets/noimage.jpg')"
                           @click="showItem(line)" />
                  </v-card>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>
                    {{line.Article.ArticleDescription}}
                  </v-list-item-title>
                  {{line.Article.ArticleNumber}}
                  <v-list-item-subtitle>
                  </v-list-item-subtitle>
                </v-list-item-content>
                {{line.ArticlePrice ? formatWithoutCurrency(line.ArticlePrice) : ''}}
                <v-list-item-action class="ma-0">
                  <v-btn icon
                         @click.stop="deleteItem(line)"
                         class="pl-1 pr-1">
                    <v-icon>mdi-delete</v-icon>
                  </v-btn>
                </v-list-item-action>
              </v-list-item>
            </v-list>
            <div class="text-right mr-3">
              Machine total: {{ formatWithoutCurrency(getPartialSum(cart)) }}
            </div>
          </v-col>
        </v-row>
        <v-divider :key="'divider-'+ index"></v-divider>
      </template>
    </v-container>
    <div style="position: fixed; 
                bottom: 0; 
                left: 0; 
                width: 100%; 
                height: 100px; 
                "
         class="grey_to_transparent_gradient">
      <v-btn :disabled="!canOrder"
             color="primary"
             style="position: fixed; 
                    bottom: 2%; 
                    left: 2%;
                    width: 96%; 
                    height: 60px;"
             @click="sendOrderConfirmedEvent()">{{ $t("text.order_now") }}</v-btn>
      <!---->
    </div>

    <!--
      <small>
        <pre>{{shoppingCart}}</pre>
      </small>
      -->
  </v-container>
</template>
 
 
 <script>
import noimage from "@/assets/noimage.jpg";
import http from "@/api/client";

export default {
  name: "Component3",
  props: {},
  components: {},

  data() {
    return {
      comment: "",
    };
  },

  mounted() {
    this.$store.dispatch("getShoppingCart");
  },

  methods: {
    imageLoadError(item /*, url*/) {
      item.ArticleImage = noimage;
    },

    getPartialSum(cart) {
      var sum = 0.0;

      if (cart) {
        cart.Items.forEach((v) => {
          sum += v.ArticlePrice * v.ArticleCount;
        });
        return (Math.round(sum * 100) / 100).toFixed(2);
      }
      return sum.toFixed(2);
    },

    deleteItem(item) {
      http()
        .delete("api2/customer/shoppingcart/detail/" + item.Id, item)
        .then(() => {
          this.$store.dispatch("silentGetShoppingCart");
          this.sendUpdateCartEvent();
        });
    },

    sendUpdateCartEvent() {
      if (window.flutter_inappwebview) {
        window.flutter_inappwebview.callHandler("updateCartEvent");
      }
    },

    sendOrderConfirmedEvent() {
      if (window.flutter_inappwebview) {
        window.flutter_inappwebview.callHandler(
          "cartOrderConfirmedEvent",
          this.comment
        );
      } else {
        this.$emit("confirmOrder");
      }
    },
    errorText(error) {
      var text;
      switch (error.status) {
        case 404:
          text = "Unable to reach backend.";
          break;
        case 401:
          text = "Invalid authentication token.";
          break;
        default:
          text = "Unable to get shopping cart." + (error.data ?? "");
      }
      return text;
    },
  },

  computed: {
    canOrder() {
      return this.shoppingCart && this.shoppingCart.length > 0;
    },

    getTotalItems() {
      if (this.shoppingCart) {
        var c = 0;
        this.shoppingCart.forEach((v) => {
          v.Items.forEach((i) => {
            c += i.ArticleCount;
          });
        });
        return c;
      }
      return 0;
    },

    getTotalSum() {
      var sum = 0.0;
      if (this.shoppingCart) {
        this.shoppingCart.forEach((item) => {
          item.Items.forEach((v) => {
            sum += v.ArticlePrice * v.ArticleCount;
          });
        });
        return (Math.round(sum * 100) / 100).toFixed(2);
      }
      return sum.toFixed(2);
    },

    shoppingCart() {
      if (this.$store.state.shoppingCart) {
        return this.$store.state.shoppingCart;
      }
      return [];
    },

    shoppingCartLoading() {
      return this.$store.state.shoppingCartLoading || false;
    },

    shoppingCartError() {
      return this.$store.state.cartError;
    },
  },

  watch: {},
};
</script>

 
 <style lang='scss' scoped>
.OddMarkedList > div:nth-child(odd):not(.li-header):not(.ignoreodd) {
  background-color: rgb(245, 249, 255);
}
.grey_to_transparent_gradient {
  background: linear-gradient(0deg, #ededed 72%, transparent 100%);
  background: -moz-linear-gradient(
    0deg,
    #ededed 72%,
    transparent 100%
  ); /* FF3.6+ */
  background: -webkit-gradient(
    0deg,
    #ededed 72%,
    transparent 100%
  ); /* Chrome,Safari4+ */
  background: -webkit-linear-gradient(
    0deg,
    #ededed 72%,
    transparent 100%
  ); /* Chrome10+,Safari5.1+ */
  background: -o-linear-gradient(
    0deg,
    #ededed 72%,
    transparent 100%
  ); /* Opera 11.10+ */
  background: -ms-linear-gradient(
    0deg,
    #ededed 72%,
    transparent 100%
  ); /* IE10+ */
  background: linear-gradient(0deg, #ededed 72%, transparent 100%); /* W3C */
}
</style>

<style>
#issuetextarea {
  max-height: 40vh !important;
  overflow: auto;
}
</style>